import('./bootstrap');

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import VueCookies from 'vue3-cookies'
import VueGtag from "vue-gtag";
import Toast from "vue-toastification";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "vue-toastification/dist/index.css";
import { i18nVue } from 'laravel-vue-i18n';
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
    progress: {
        color: '#4B5563',
    },
    title: title => title ? `${title} - ${appName}` : appName,
    resolve: async (name) => {
        let componentPath = `./Pages/${name}`
            // If name starts with ./theme then it means we have the full component path in the name itself
        if (name.startsWith('./theme')) {
            componentPath = name
        }
        if (! componentPath.endsWith('.vue')) {
            componentPath = `${componentPath}.vue`;
        }

        return resolvePageComponent(`${componentPath}`, import.meta.glob('./Pages/**/*.vue'))
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18nVue, {
                resolve: lang => {
                    const langs = import.meta.globEager('../../lang/*.json');
                    return langs[`../../lang/${lang}.json`].default;
                }
            })
            .use(VueCookies)
            .use(Toast)
            .use(VueGtag, {
                config: { id: props.initialPage.props.gtag }
            })
            .use(VueReCaptcha, {
                siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
                loaderOptions: {
                    useRecaptchaNet: true,
                    autoHideBadge: true
                }
            });

        // Initial Sentry only in non-local environments
        if (import.meta.env.VITE_APP_ENV === 'production') {
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                release: props.initialPage.props.release,
                trackComponents: true,
                environment: import.meta.env.VITE_APP_ENV,
                integrations: [
                    new Sentry.BrowserTracing({
                        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                        tracePropagationTargets: ["localhost", /^https:\/\/deeprootsbible\.com\//]
                    }),
                    new Sentry.Replay(),
                ],
                // Performance Monitoring
                tracesSampleRate: 0, // Use lower value on production. 1.0 is max.
                // Session Replay
                replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
                ignoreErrors:[
                    "Non-Error exception captured",
                    "Non-Error promise rejection captured"
                ],
            });
        }

        app.mixin({ methods: { route } })
            .mount(el);
    },
});
